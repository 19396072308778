import { Address } from '@medusajs/medusa';
import { Form, Modal } from 'antd';
import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';

import { useCustomer } from '../../../hooks/use-customer';
import { useMedusa } from '../../../hooks/use-medusa';
import notification from '../../utility/notification';
import AddressForm, { AddressFormSchema } from './AddressForm';

const EditAddressFormModal = ({
  selectedAddress,
  resetAction,
}: {
  selectedAddress: Address | null;
  resetAction: () => void;
}) => {
  const {
    actions: { me, setDefaultShippingAddress },
  } = useCustomer();
  const client = useMedusa();

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm<AddressFormSchema>();
  const [isDefault, setIsDefault] = useState(false);

  useEffect(() => {
    if (selectedAddress) {
      form.setFieldsValue({
        first_name: selectedAddress.first_name || '',
        last_name: selectedAddress.last_name || '',
        company: selectedAddress.company || '',
        address_1: selectedAddress.address_1 || '',
        address_2: selectedAddress.address_2 || '',
        country_code: selectedAddress.country_code || '',
        province: selectedAddress.province || '',
        is_default: !!selectedAddress.metadata?.isDefault,
        postal_code: selectedAddress.postal_code || '',
        phone: selectedAddress.phone || '',
      });

      setIsDefault(!!selectedAddress.metadata?.isDefault);
    }
  }, [selectedAddress]);

  const onSubmit = async (formValues: AddressFormSchema) => {
    if (!selectedAddress) return;

    setLoading(true);

    try {
      await client.customers.addresses.updateAddress(selectedAddress.id, {
        ...omit(formValues, ['is_default']),
        city: formValues.province,
      });

      if (formValues.is_default) {
        await setDefaultShippingAddress(selectedAddress);
      }

      await me();

      notification({
        key: 'update-address-success',
        type: 'success',
        message: 'แก้ไขที่อยู่สำเร็จ',
      });

      resetAction();
      form.resetFields();
    } catch (error) {
      notification({
        key: 'update-address-error',
        type: 'error',
        message: 'พบข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={!!selectedAddress}
      onCancel={() => resetAction()}
      title="แก้ไขที่อยู่จัดส่ง"
      okText="Submit"
      onOk={() => form.submit()}
      confirmLoading={loading}
      width={600}
    >
      <div className="-mb-2">
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          disabled={loading}
        >
          <AddressForm disabledIsDefault={!!isDefault} />
        </Form>
      </div>
    </Modal>
  );
};

export default EditAddressFormModal;
