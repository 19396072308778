import { Form, Modal } from 'antd';
import { omit } from 'lodash';
import React, { useState } from 'react';

import { useCustomer } from '../../../hooks/use-customer';
import notification from '../../utility/notification';
import AddressForm, { AddressFormSchema } from './AddressForm';

const AddressFormModal = ({
  open = false,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const {
    actions: { me, addShippingAddress },
  } = useCustomer();

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm<AddressFormSchema>();

  const onSubmit = async (formValues: AddressFormSchema) => {
    setLoading(true);

    try {
      await addShippingAddress(
        {
          ...omit(formValues, 'is_default'),
          city: formValues.province,
          metadata: {},
        },
        formValues.is_default
      );

      await me();

      notification({
        key: 'add-address-success',
        type: 'success',
        message: 'เพิ่มที่อยู่ใหม่สำเร็จ',
      });

      setOpen(false);
      form.resetFields();
    } catch (error) {
      notification({
        key: 'add-address-error',
        type: 'error',
        message: 'พบข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={open}
      onCancel={() => setOpen(false)}
      title="New Address"
      okText="Submit"
      onOk={() => form.submit()}
      confirmLoading={loading}
      width={600}
    >
      <div className="-mb-2">
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          disabled={loading}
        >
          <AddressForm />
        </Form>
      </div>
    </Modal>
  );
};

export default AddressFormModal;
