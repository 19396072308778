import React from 'react';

const PlusIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.125 5.625C13.125 5.32663 13.0065 5.04048 12.7955 4.8295C12.5845 4.61853 12.2984 4.5 12 4.5C11.7016 4.5 11.4155 4.61853 11.2045 4.8295C10.9935 5.04048 10.875 5.32663 10.875 5.625V10.875H5.625C5.32663 10.875 5.04048 10.9935 4.8295 11.2045C4.61853 11.4155 4.5 11.7016 4.5 12C4.5 12.2984 4.61853 12.5845 4.8295 12.7955C5.04048 13.0065 5.32663 13.125 5.625 13.125H10.875V18.375C10.875 18.6734 10.9935 18.9595 11.2045 19.1705C11.4155 19.3815 11.7016 19.5 12 19.5C12.2984 19.5 12.5845 19.3815 12.7955 19.1705C13.0065 18.9595 13.125 18.6734 13.125 18.375V13.125H18.375C18.6734 13.125 18.9595 13.0065 19.1705 12.7955C19.3815 12.5845 19.5 12.2984 19.5 12C19.5 11.7016 19.3815 11.4155 19.1705 11.2045C18.9595 10.9935 18.6734 10.875 18.375 10.875H13.125V5.625Z"
        fill="white"
      />
    </svg>
  );
};

export default PlusIcon;
